import { graphql, PageProps } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import * as React from 'react';
import Layout from '../components/layout';
import MDXComponents from '../components/mdxComponents';
import Info from '../components/info';
import Share from '../components/share';
import BlogPager from '../components/blogPager';

export interface Pager {
  link: string;
  title: string;
}

interface IPageContext {
  prev: Pager;
  next: Pager;
}

const BlogPost: React.FC<PageProps<GatsbyTypes.BlogDataQuery, IPageContext>> = ({ data, pageContext }) => {
  const frontmatter = data.mdx?.frontmatter;
  const body = data.mdx?.body;
  const excerpt = data.mdx?.excerpt!;
  const path = `${data.mdx?.frontmatter?.date!.replace(/\//g, '-')}/${data.mdx?.slug}`;
  return (
    <Layout pageTitle={frontmatter?.title!} description={excerpt} url={path}>
      <h1>{frontmatter?.title}</h1>
      <Info tags={frontmatter?.tags} date={frontmatter?.date} />
      {body && (
        <MDXProvider components={MDXComponents}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      )}
      <Share title={frontmatter?.title!} path={path} />
      <BlogPager prev={pageContext.prev} next={pageContext.next} />
    </Layout>
  );
};

export const query = graphql`
  query BlogData($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "YYYY/MM/DD")
        tags
      }
      body
      slug
      excerpt
    }
  }
`;

export default BlogPost;
