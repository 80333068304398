import * as React from 'react';
import Socials from '../socials';
import * as style from './style.module.css';

interface IProps {
  title: string;
  path: string;
}

const Share: React.VFC<IProps> = ({ title, path }) => (
  <div className={style.share}>
    <hr />
    <p className={style.text}>記事のシェアはこちら</p>
    <Socials title={title} path={path} />
  </div>
);

export default Share;
