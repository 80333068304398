import * as React from 'react';
import * as style from './style.module.css';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MDXComponents = {
  p: (props: React.HTMLAttributes<HTMLElement>) => <p className={`${style.text} ${style.paragprah}`} {...props} />,
  h1: (props: React.HTMLAttributes<HTMLElement>) => (
    <h1 className={`${style.text} ${style.heading}`} {...props} id={String(props.children)}>
      {props.children}
      <span className={style.linkstyle}>
        <a href={`#${String(props.children)}`}>
          <FontAwesomeIcon icon={faLink} color="#232129" size="xs" />
        </a>
      </span>
    </h1>
  ),
  h2: (props: React.HTMLAttributes<HTMLElement>) => (
    <h2 className={`${style.text} ${style.heading}`} {...props} id={String(props.children)}>
      {props.children}
      <span className={style.linkstyle}>
        <a href={`#${String(props.children)}`}>
          <FontAwesomeIcon icon={faLink} color="#232129" size="xs" />
        </a>
      </span>
    </h2>
  ),
  h3: (props: React.HTMLAttributes<HTMLElement>) => (
    <h3 className={`${style.text} ${style.heading}`} {...props} id={String(props.children)} />
  ),
  h4: (props: React.HTMLAttributes<HTMLElement>) => (
    <h4 className={`${style.text} ${style.heading}`} {...props} id={String(props.children)} />
  ),
  h5: (props: React.HTMLAttributes<HTMLElement>) => (
    <h5 className={`${style.text} ${style.heading}`} {...props} id={String(props.children)} />
  ),
  h6: (props: React.HTMLAttributes<HTMLElement>) => (
    <h6 className={`${style.text} ${style.heading}`} {...props} id={String(props.children)} />
  ),
  blockquote: (props: React.HTMLAttributes<HTMLElement>) => <blockquote className={style.blockquote} {...props} />,
  li: (props: React.HTMLAttributes<HTMLElement>) => <li className={style.listitem} {...props} />,
  strong: (props: React.HTMLAttributes<HTMLElement>) => <strong className={style.strong} {...props} />,
  hr: (props: React.HTMLAttributes<HTMLElement>) => <hr className={style.breakline} {...props} />,
  table: (props: React.HTMLAttributes<HTMLElement>) => <table className={style.table} {...props} />,
  th: (props: React.HTMLAttributes<HTMLElement>) => <th className={style.th} {...props} />,
  td: (props: React.HTMLAttributes<HTMLElement>) => <td className={style.td} {...props} />,
};

export default MDXComponents;
