// extracted by mini-css-extract-plugin
export var blockquote = "style-module--blockquote--tTaj0";
export var breakline = "style-module--breakline--FSYw0";
export var heading = "style-module--heading--mVpB+";
export var linkstyle = "style-module--linkstyle--hxD1p";
export var listitem = "style-module--listitem--TVLIx";
export var paragprah = "style-module--paragprah--9GLxa";
export var strong = "style-module--strong--yuDVT";
export var table = "style-module--table--h-tSi";
export var td = "style-module--td--9tt6q";
export var text = "style-module--text--Iutmk";
export var th = "style-module--th--cvptF";