import * as React from 'react';
import { HatebuButton, TwitterButton } from '../socialbutton';
import * as styles from './style.module.css';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  title: string;
  path: string;
}

const Socials: React.VFC<IProps> = ({ title, path }) => (
  <div className={styles.socials}>
    <FontAwesomeIcon icon={faShareAlt} color="#232129" size="2x" />
    <TwitterButton title={title} path={path} />
    <HatebuButton path={path} />
  </div>
);

export default Socials;
