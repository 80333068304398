import { Link } from 'gatsby';
import * as React from 'react';
import * as style from './style.module.css';
import { Pager } from '../../pages/blog';

interface IProps {
  prev: Pager;
  next: Pager;
}

const BlogPager: React.VFC<IProps> = ({ prev, next }) => (
  <div className={style.container}>
    {next ? (
      <Link className={style.pagerLink} to={next.link}>
        &lt; {next.title}
      </Link>
    ) : (
      <div />
    )}
    <Link className={style.pagerLink} to="/">
      Home
    </Link>
    {prev ? (
      <Link className={style.pagerLink} to={prev.link}>
        {prev.title} &gt;
      </Link>
    ) : (
      <div />
    )}
  </div>
);

export default BlogPager;
