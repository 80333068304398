import * as React from 'react';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const generateLink = (text: string) => {
  return `https://twitter.com/intent/tweet?text=${encodeURI(text)}`;
};

interface IProps {
  title: string;
  path: string;
}

const TwitterButton = React.memo<IProps>(({ title, path }) => {
  const text = `${title} - https://jiko21-tech-blog.monster/blog/${path}`;
  const link = generateLink(text);
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faTwitterSquare} color="#1DA1F2" size="2x" />
    </a>
  );
});

export default TwitterButton;
