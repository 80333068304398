import * as React from 'react';
import hatenaLogo from './hatena.svg';
import * as styles from './styles.module.css';

interface IProps {
  path: string;
}

const HatebuButton = React.memo<IProps>(({ path }) => {
  const link = `https://b.hatena.ne.jp/entry?url=https://jiko21-tech-blog.monster/blog/${path}`;
  return (
    <a href={link} target="_blank" rel="noreferrer" className={styles.wrapper}>
      <img height={28} src={hatenaLogo} />
    </a>
  );
});

export default HatebuButton;
